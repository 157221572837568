import __pagesSlugsMap from '~/components/pages/pagesSlugs.map.js'
import __projectConfig from '~/project.config.js'

export type TPathSettings = {
  localize?: boolean
  type?: string
}

const pathApi = {
  path(url: string, settings?: TPathSettings): string {
    const localePath = useLocalePath()

    // null or undefined
    if (!url) {
      return '/'
    }

    // skip external urls
    if (url.startsWith('http')) {
      return url
    }

    // handle homepage slug
    if (url === __projectConfig.dato?.homepageSlug) {
      url = '/'
    }

    // handle pages slugs
    if (settings?.type && __pagesSlugsMap[settings.type]) {
      url = `${__pagesSlugsMap[settings.type]}/${url}`
    }

    // append / to the url
    if (!url.startsWith('/')) {
      url = `/${url}`
    }

    // localize path
    url = localePath(url)

    // make sure we don't have double slashes
    url = url.replace(/\/{2,}/g, '/')

    // return path
    return url
  },
}

export default function usePath(): typeof pathApi {
  return pathApi
}
